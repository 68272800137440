import React from "react";
import "./Card1.css"; // Import your CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faCertificate,
  faClock,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons"; // Import necessary FontAwesome icons

const Card1 = () => {
  return (
    <>
    <h2 className="infot">What We Offer</h2>
    <h1 className="infot2">For Your Future Learning.</h1>
    <div className="cards-container">
         
      <div className="card">
        <div className="icon">
          <FontAwesomeIcon
            icon={faChalkboardTeacher}
            style={{ color: "#FF5733", fontSize: "3rem" }}
          />
        </div>
        <h2>Offline and Online Courses</h2>
        <p>
          Interactively provide access world class materials for unique
          catalysts for change my ocardinat.
        </p>
      </div>

      <div className="card">
        <div className="icon">
          <FontAwesomeIcon
            icon={faGraduationCap}
            style={{ color: "#FFC300", fontSize: "3rem" }}
          />
        </div>
        <h2>Expert Trainer</h2>
        <p>
          Interactively provide access world class materials for unique
          catalysts for change my ocardinat.
        </p>
      </div>

      <div className="card">
        <div className="icon">
          <FontAwesomeIcon
            icon={faCertificate}
            style={{ color: "#C70039", fontSize: "3rem" }}
          />
        </div>
        <h2>Get Certificate</h2>
        <p>
          Interactively provide access world class materials for unique
          catalysts for change my ocardinat.
        </p>
      </div>

      <div className="card">
        <div className="icon">
          <FontAwesomeIcon
            icon={faClock}
            style={{ color: "#900C3F", fontSize: "3rem" }}
          />
        </div>
        <h2>Life Time Access</h2>
        <p>
          Interactively provide access world class materials for unique
          catalysts for change my ocardinat.
        </p>
      </div>
    </div>
    </>
  );
};

export default Card1;
