import React from 'react';
import { Link } from "react-router-dom";


const TermsAndConditions = () => {


  return (

    <> 
      <div>
          <h1>Terms and Conditions</h1>
          <section>
              <h2>1. Introduction</h2>
              <p>These terms and conditions govern the use of Karishna Academy (Santosh Kutwal)’s services, website, and any related materials. By accessing or using our services, you agree to comply with these terms.</p>
          </section>
          <section>
              <h2>2. Definitions</h2>
              <ul>
                  <li><strong>Karishna Academy:</strong> Refers to our educational institute.</li>
                  <li><strong>User:</strong> Any individual accessing our services.</li>
                  <li><strong>Services:</strong> Includes courses, workshops, events, and any other offerings provided by Karishna Academy (Santosh Kutwal)’s.</li>
              </ul>
          </section>
          <section>
              <h2>3. Use of Services</h2>
              <ul>
                  <li>Users must adhere to all rules and policies set forth by Karishna Academy (Santosh Kutwal)’s .</li>
                  <li>Unauthorized use, sharing of login credentials, or distribution of course materials is strictly prohibited.</li>
                  <li>Users are responsible for maintaining the confidentiality of their accounts.</li>
              </ul>
          </section>
          <section>
              <h2>4. Payment and Refunds</h2>
              <p>Kindly refer to our <Link className="dropdown-item" to="/22">
                Payment And Refund Policy
                </Link> for details.</p>
          </section>
          <section>
              <h2>5. Intellectual Property</h2>
              <ul>
                  <li>All course materials, content, and resources are the property of Karishna Academy (Santosh Kutwal)’s.</li>
                  <li>Users may not reproduce, distribute, or modify any materials without written consent.</li>
              </ul>
          </section>
          <section>
              <h2>6. Privacy</h2>
              <ul>
                  <li>We collect and process personal data in accordance with our privacy policy.</li>
                  <li> Users are encouraged to review our <Link className="dropdown-item" to="/privacy-policy" > privacy policy for details.  </Link></li>
              </ul>
          </section>
          <section>
              <h2>7. Disclaimer</h2>
              <ul>
                  <li>Karishna Academy (Santosh Kutwal)’s strives for accuracy, but we do not guarantee the completeness or correctness of information provided.</li>
                  <li>Users should verify information independently.</li>
              </ul>
          </section>
          <section>
              <h2>8. Modifications</h2>
              <p>We reserve the right to update these terms and conditions. Changes will be posted on our website.</p>
          </section>
      </div>
    </>
      
        
       
     
      
  
  );
};

export default TermsAndConditions;