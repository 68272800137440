import React from 'react'
import './MCA.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';


const BCA = () => {
  return (
    <div className="mcabca-page">
      <h1>Welcome to BCA Courses</h1>
      <div className="container">
        <div className="row">
         
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>BCA Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for BCA Course</p>
                <p>Bachelor of Computer Applications (BCA) is an undergraduate program that provides a strong foundation in computer science and its applications.</p>
                <p>Benefits after completing BCA:</p>
                <ul>
                  <li>Entry-level positions in the IT industry</li>
                  <li>Preparation for advanced studies in computer science</li>
                  <li>Development of analytical and problem-solving skills</li>
                </ul>
                <p>Job Opportunities:</p>
                <p>After completing BCA, graduates can pursue careers as:</p>
                <ul>
                  <li>Web Developer</li>
                  <li>Software Tester</li>
                  <li>Technical Support Executive</li>
                  <li>IT Sales Professional</li>
                </ul>
                <p>Placement Assistance: We offer comprehensive placement support to our BCA graduates to help them kick-start their careers in the IT industry.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BCA;
