// About.js

import React from "react";
import { useEffect, useRef } from "react";
import "../About/About.css"; // Import your dynamic CSS file for styling
import image2 from "../Images/image3.jpg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faFlask } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  const visionRef = useRef(null);

  useEffect(() => {
    const visionElement = visionRef.current;

    const slideIn = () => {
      if (visionElement) {
        visionElement.classList.add("slide-in");
      }
    };

    slideIn();
  }, []);


  return (
    <>
      <div className="about-container">
        <div className="about-background"></div>
        <h2 className="about-heading">About Us</h2>
        <p className="about-description">
          Founded in 2012, our institute has been at the forefront of empowering
          <br />
          IT professionals with the best education and training. Our commitment
          to
          <br />
          excellence has seen us become a beacon of knowledge for those aspiring
          <br />
          to excel in the IT industry.
        </p>
      </div>

      <div>
        <div className="services-container">
          <div className="service">
            <h3>Comprehensive IT Training</h3>
            <p>
              Karishna Academy specializes in delivering comprehensive IT
              training, complete with course materials, interview coaching, and
              internships involving real-time IT industry projects. We cater to
              a diverse audience by offering daytime, weekend, and accelerated
              training sessions for students, staff, and working professionals
              alike.
            </p>
          </div>
          <div className="service">
            <h3>Market-Oriented Curriculum</h3>
            <p>
              Our curriculum is designed to address the most sought-after
              technologies in today’s market, ensuring our candidates are
              well-equipped to thrive in a competitive global environment.
            </p>
          </div>
          <div className="service">
            <h3>Hands-On Learning</h3>
            <p>
              As a distinguished IT institute, we boast an array of courses
              backed by our faculty’s exceptional ability to enhance and refine
              student capabilities. This empowers our students to become
              innovators within the industry. By providing practical, real-world
              experiences, we ensure our students gain the proficiency needed to
              excel in their professional roles.
            </p>
          </div>
        </div>
      </div>

      <div className="about-page">
        <h2>Our Programs</h2>
        <div className="programs-container">
          <div className="program">
          <div className="education-logo">
      <FontAwesomeIcon icon={faDesktop} className="desktop-icon" />
      <span className="education-text">IT Professional Education</span>
    </div>
         
            <p>
              Cutting-edge courses designed to keep you ahead in the IT sector.
            </p>
          </div>
          <div className="program">
          <div className="science-coaching-logo">
      <FontAwesomeIcon icon={faFlask} className="flask-icon" />
      
      <span className="coaching-text">Science Coaching for 11th and 12th Grades</span>
    </div>
            <p>
              Specialized coaching classes to prepare students for competitive
              exams like CET, NEET, and JEE.
            </p>
          </div>
        </div>
      </div>

      <div className="about-page">
        <div className="vision-section">
        <div className="l-c">
      <FontAwesomeIcon
        icon={faEye}
        className="l"
      />
    </div>
          <h2>Our Vision</h2>
          <p>
            To be the leading educational institute that nurtures talent and
            transforms the lives of our students through comprehensive and
            industry-aligned learning experiences.
          </p>
        </div>
        <div className="mission-section">
        <div className="mission-logo">
      <FontAwesomeIcon icon={faRocket} className="rocket-icon" />
 
    </div>
          <h2>Our Mission</h2>
          <p>
            To provide unparalleled education that equips IT professionals with
            the skills and knowledge required to succeed in the ever-evolving
            technological landscape.
          </p>
        </div>
      </div>

      <div className="about-page">
        <div className="impact-section">
       < div className="impact-logo">
      <FontAwesomeIcon icon={faHandHoldingHeart} className="heart-icon" />
      
    </div>
          <h2 className="impact-title">Our Impact</h2>
          <div className="impact-content">
            <p>
              Over the years, we have educated more than 9,000 students, helping
              them achieve their dreams and aspirations. Our alumni are a
              testament to our quality education, as they continue to make
              significant contributions to the IT world and beyond.
            </p>
          </div>
        </div>
      </div>

      <div className="about-page">
        <div className="commitment-section">
          <h2>Our Commitment to Your Future</h2>
          <div className="support-box">
          <div className="placement-support-logo">
      <FontAwesomeIcon icon={faHandshake} className="handshake-icon" />
      
    </div>
            <p>
              We take pride in having placed over 1000+ trained professionals in
              leading IT companies.
            </p>
          </div>
          <div className="support-box">
          <div className="support-logo">
      <FontAwesomeIcon icon={faShieldAlt} className="shield-icon" />
      
    </div>
            <p>
              We are dedicated for providing 100% support for the placement of
              our students, ensuring they are ready to embark on successful
              careers.
            </p>
          </div>
        </div>
        <p className="join-us">
          Join us and be a part of our journey to shape the future of
          technology.
        </p>
      </div>

      <div className="why-choose-us-container">
  <div className="why-choose-us-card">
    <img src={image2} alt="Side " className="side-image" />
    <div className="content">
      <h2>Why Choose Karishna Academy?</h2>
      <p>Our instructors are highly certified and experienced professionals with over 10 years of real-world experience. We provide high-quality IT training across India.</p>
      <h3>Increasing Your Learning Skills</h3>
      <ul>
        <li>Good Teaching Quality</li>
        <li>High Practical Knowledge</li>
        <li>Finish Your Course, Get Certificate</li>
      </ul>
      <h3>Explore Courses</h3>
      <p>Discover our wide range of courses and start your journey to a successful career in IT.</p>
    </div>
  </div>
</div>
    </>
  );
};

export default About;
