// PrivacyPolicy.js

import {React }from "react";
import "./PrivacyPolicy.css";



const PrivacyPolicy = () => {



  return (
    <>
 
      <div className="privacy-content">
        <h2>Privacy Policy</h2>
        <div>
          We value the trust you place in us. That’s why we insist upon the
          highest standards for secure transactions and customer information
          privacy. Please read the following statement to learn about our
          information gathering and dissemination practices.<p>Note: Our privacy
          policy is subject to change at any time without notice. To make sure
          you are aware of any changes, please review this policy periodically</p>
          By visiting this Website you agree to be bound by the terms and
          conditions of this Privacy Policy. If you do not agree please do not
          use or access our Website. By mere use of the Website, you expressly
          consent to our use and disclosure of your personal information in
          accordance with this Privacy Policy. This Privacy Policy is
          incorporated into and subject to the Terms of Use.<p> Collection of
          Personally Identifiable Information and other Information When you use
          our Website, we collect and store your personal information which is
          provided by you from time to time. Our primary goal in doing so is to
          provide you a safe, efficient, smooth and customized experience. This
          allows us to provide services and features that most likely meet your
          needs, and to customize our Website to make your experience safer and
          easier. More importantly, while doing so we collect personal
          information from you that we consider necessary for achieving this
          purpose. In general, you can browse the Website without telling us who
          you are or revealing any personal information about yourself. Once you
          give us your personal information, you are not anonymous to us. Where
          possible, we indicate which fields are required and which fields are
          optional.</p> You always have the option to not provide information by
          choosing not to use a particular service or feature on the Website. If
          you transact with us, we collect some additional information, such as
          a billing address, a credit / debit card number and a credit / debit
          card expiration date and/ or other payment instrument details and
          tracking information from cheques or money orders. If you send us
          personal correspondence, such as emails or letters, or if other users
          or third parties send us correspondence about your activities or
          postings on the Website, we may collect such information into a file
          specific to you. Use of Demographic / Profile Data / Your Information
          We use personal information to provide the services you request.<p> To
          the extent we use your personal information to market to you, we will
          provide you the ability to opt-out of such uses. We use your personal
          information to resolve disputes; troubleshoot problems; help promote a
          safe service; collect money; measure consumer interest in our products
          and services, inform you about online and offline offers, products,
          services, and updates; customize your experience; detect and protect
          us against error, fraud and other criminal activity; enforce our terms
          and conditions; and as otherwise described to you at the time of
          collection.</p>
        
        {/* More paragraphs and sections */}

        
      
    </div>
    </div>
    
  

</>
  );
};

export default PrivacyPolicy;
