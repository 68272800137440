import React from "react";
import "./Aboutcard.css"; // Import CSS file for styling
import Aboutcar from "../Images/Aboutcard.jpg";
import { Link } from "react-router-dom";

const Aboutcard = () => { 
  return (
    <div className="about-us-card">
      <img src={Aboutcar} alt="Aboutcard" />
      <div className="conte">
        <h2 style={{textAlign:"center", color:"red"}}>About Us </h2>
        <h6>Welcome To Karishna Academy Pune</h6>
        <p>
          Karishna Academy is a leading 10/12/NEET/JEEE and IT training
          institute which provides real-time and advanced technology training
          followed by on-the-job industry training and stipends. Our students
          and working professionals have benefited from our training network.
          More than 80% of our students are placed with renowned companies. We
          have a training center at Kharadi Pune.
        </p>
        <Link to="/about">Read More</Link>
      </div>
    </div>
  );
};

export default Aboutcard;
