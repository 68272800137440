import React, { useState } from 'react';
import axios from 'axios';
import '../Paymentgateways/Paymentgateways.css';

function Paymentgateways() {
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    email: '',
    amount: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://localhost:5000/api/payment', formData)
      .then(response => {
        // handle response from backend
        console.log(response.data);
        // Redirect to PayU payment gateway
        const payUForm = document.createElement('form');
        payUForm.action = response.data.payUURL;
        payUForm.method = 'POST';

        Object.keys(response.data).forEach(key => {
          if (key !== 'payUURL') {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = response.data[key];
            payUForm.appendChild(input);
          }
        });

        document.body.appendChild(payUForm);
        payUForm.submit();
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  return (
    <div className="App">
      <h1>Payment Page</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Mobile Number:</label>
          <input type="text" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Amount:</label>
          <input type="number" name="amount" value={formData.amount} onChange={handleChange} required min="1" max="200000" />
        </div>
        <button type="submit">Pay Now</button>
      </form>
    </div>
  );
}

export default Paymentgateways;
