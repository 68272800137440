import React from 'react';
import  { useEffect, useRef,useState} from 'react';
import './Home.css';
import InquiryForm from '../InquiryForm/InquiryForm';

import Image from '../Images/tp1.png';
import Card1 from '../Heropage/Card1';
import Aboutcard from '../Heropage/Aboutcard';
import TopCategories from '../Heropage/TopCategories';
import Placements from '../Placements/Placements';
import ImageSlider from './ImageSlider';
import Certificate from '../Certificate/Certificate'






const Home = () => {



  
  const welcomeRef = useRef(null);

  useEffect(() => {
    const welcomeElement = welcomeRef.current;

    const slideIn = () => {
      if (welcomeElement) {
        welcomeElement.classList.add('slide-in');
      }
    };

    slideIn();
  }, []);

// inquriform
const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    // Display the form when the component mounts
    setShowForm(true);
  }, []);

  const handleCloseForm = () => {
    // Close the form
    setShowForm(false);
  };


  return (
    
  
    <>
    <div className='bag'>
     <div className='bag2'>
    
   
     <img  className="Rimage"  src={Image} alt="Slide 1"  />
     </div>
      <div className="home-container">
      <h4 ref={welcomeRef} className="home-welcome">Welcome to Karishna Academy..</h4>
     </div>
     </div>
     
     
     <div>
      {/* Other content */}
      {showForm && <InquiryForm onClose={handleCloseForm} />}
      {/* Other content */}
    </div>
    <Card1/>
    <Aboutcard/>
    <div style={{marginBottom:"10px"}}>
    <TopCategories/>
    <ImageSlider/>
    </div>
    <Placements/>
  
    <Certificate />
   
  </>
  );
};

export default Home;