import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import "./Branches.css"; // Import your CSS file for styling

const Branches = () => {
  return (
    <div className="cards-containera">
      <div className="cardb">
        <h3>Branch 1</h3>
        <p>
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="icon"
            style={{ color: "red" }}
          /> 
          <a
            href="https://maps.app.goo.gl/Ciix95ERtfsdFLrR6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Karishna Academy, on by-pass road, Opp NOVA IVF Center, Acolade
            society road corner, Sambhaji Nagar, Kharadi, Pune
          </a>
       
        </p>
      </div>
      <div className="cardb">
        <h3>Branch 2</h3>
        <p>
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="icon"
            style={{ color: "red" }}
          />{" "}
          Address: Karishna Academy Raigad Colony- E, Thite Vasti, Kharadi,
          Pune, Maharashtra 411014
        </p>
      </div>
      <div className="cardb">
        <h3>Contact number</h3>
        <p>
          <FontAwesomeIcon
            icon={faPhone}
            className="icon"
            style={{ color: "green" }}
          />{" "}
          +91-8983498396
          <br />
          <FontAwesomeIcon icon={faPhone} className="icon" /> +91-8434537020
        </p>
      </div>
      <div className="cardb">
        <h3>Email ID</h3>
        <p>
          <FontAwesomeIcon
            icon={faEnvelope}
            className="icon"
            style={{ color: "red" }}
          />{" "}
          karishnaacademy@gmail.com
          <br />
          <FontAwesomeIcon
            icon={faEnvelope}
            className="icon"
            style={{ color: "white" }}
          />{" "}
          datascience.classes22@gmail.com
        </p>
      </div>
    </div>
  );
};

export default Branches;
