// DataScienceCard.js
import React from 'react';
import './AWS.css';
import dataScienceImage from '../Images/DataScience.jpg'; // Import your image file







import jsPDF from '../Certificate/jsPDF3.pdf';


import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { AiOutlineDownload } from "react-icons/ai";
import {pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const DataScience = () => {
  const awsData = {
    title: 'Data Science ',
    description: 'Explore our Data Science services .',
    image: dataScienceImage,
  };

  
  

  return (
    <div className="aws-devops-page">
      <h1>Data Science</h1>
      <div className="card-container">
        <div className="card">
          <img src={awsData.image} alt="AWS"  />
          <div className="card-content">
            <h2>{awsData.title}</h2>
            <p>{awsData.description}</p>
            <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={jsPDF}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download
          </Button>
        </Row>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default DataScience;
