import React from 'react'
import './MCA.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';


const MCA = () => {
  return (
    <div className="mcabca-page">
      <h1>Welcome to MCA Course</h1>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>MCA Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for MCA Course</p>
                <p>Master of Computer Applications (MCA) is a postgraduate program that focuses on providing theoretical as well as practical knowledge about computer applications.</p>
                <p>Benefits after completing MCA:</p>
                <ul>
                  <li>High demand in the IT industry</li>
                  <li>Opportunities for higher salary</li>
                  <li>Versatile career options</li>
                </ul>
                <p>Job Opportunities:</p>
                <p>After completing MCA, graduates can pursue careers as:</p>
                <ul>
                  <li>Software Developer</li>
                  <li>System Analyst</li>
                  <li>Database Administrator</li>
                  <li>Network Administrator</li>
                </ul>
                <p>Placement Assistance: We provide dedicated placement support to help our MCA graduates secure lucrative job opportunities.</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default MCA;
