import React from 'react'




function Courses() {
  return (
    <div>
     
    </div>
  )
}

export default Courses
