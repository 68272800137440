import React from 'react';
import './Certificate.css'; // Import CSS file for styling
import comp from './companies.jpg';


const Certificate = () => {
  return (
    <div className="placement-page">
      <div className="placement-card">
   
      <h2>500+ Students Placed</h2>
  
      <p>
        Congratulations to all the students who have been placed in leading IT companies. We are proud of your achievements
        and wish you all the best for your future endeavors.
      </p>
    </div>
    
      <div className="poster-container">
        <img src={comp} alt="Placement Poster" />
      </div>
    </div>
  );
};

export default Certificate;

