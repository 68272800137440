// WebDevelopment.js
import React from 'react';
import './WebDevelopment.css';
import Front from '../Images/Front.jpg';
import backd from '../Images/backd.jpg';


const WebDevelopment = () => {
  return (
    <div className="web-development-container">
      <div className="web-development-card">
        <img src={Front} alt="Webf" className="web-development-image" />
        <div className="web-development-content">
          <h2>Frontend Development</h2>
          <p>Learn the latest frontend technologies like HTML, CSS, JavaScript, and modern frameworks such as React and Angular.</p>
        </div>
      </div>
      <div className="web-development-card">
        <img src={backd} alt="Webb" className="web-development-image" />
        <div className="web-development-content">
          <h2>Backend Development</h2>
          <p>Master backend technologies like Node.js, Express, MongoDB, SQL, and RESTful API development.</p>
        </div>
      </div>
    </div>
  );
};

export default WebDevelopment;
