import React from 'react'

function College() {
  return (
    <div>
      hi othere
    </div>
  )
}

export default College
