// Footer.js

import React from 'react';
import './Footer.css';
import SocialFollow from '../SocialFollow/SocialFollow';
import calllogo from '../Images/calllogo.png';
import whatsapp from '../Images/whatsapp.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Branches from './Branches';

const Footer = () => {

  const handleWhatsAppClick = () => {
    // Replace `1234567890` with the recipient's phone number
    const phoneNumber = '+91-8983498396';
    // Replace `Hello%20there!` with the message you want to send
    const message = 'Hello%20there!';
    // Construct the WhatsApp link
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };


  return (
    <footer className="footer">
      
      
      <div className="footer-content">
       <div> <Branches />
       <h3>Follow Us</h3>
          <SocialFollow />
        </div>
  <div>
     <div className="logo-container">
   <a href="tel:8983498396"><img src={calllogo} alt='callme' className="logo"/></a>
 

</div>
 




   <div className="logo-conta">
   
        <img
       src={whatsapp}
        alt="WhatsApp Logo"
        
        className="logo"
        onClick={handleWhatsAppClick}
        />

      
      </div>




          
        </div>
       


      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Karishna Academy. All Rights Reserved.</p>
        <p>Developed and Designed by Mr.Krushna Chavan |<FontAwesomeIcon icon={faWhatsapp} />+91-9764065434</p>
      </div>
      

     </footer>
  );
};

export default Footer;
