// Contact.js

import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


const Contact = () => {
  return (
    <>
    <div className="container">
      <div className="contact-form">
        <h2>Send Us a Message</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" placeholder="Your Name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Id</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="comments">Comments</label>
            <textarea
              id="comments"
              name="comments"
              placeholder="Enter Your Message"
            ></textarea>
          </div>
          <button type="submit">Submit Now</button>
        </form>
      </div>

      
     
    </div>


    <div className="contact-card">
    <div className="contact-logo">
    <FontAwesomeIcon icon={faPhone} />
    </div>

      <h3>Contact</h3>
      <p>+91-8983498396</p>
      <p>+91-8434537020</p>
      </div>
            
         
      <div className="address-card">
      <div className="address-logo">
      <FontAwesomeIcon icon={faMapMarkerAlt} />
      </div>
            <h3>Address</h3>
            <h5>❖Branch 1❖</h5>
            <p>
              <a
                href="https://maps.app.goo.gl/Ciix95ERtfsdFLrR6"
                target="_blank"
                rel="noopener noreferrer"
              >

               
              </a>
              
            </p>
            <h5>❖Branch 2❖</h5> 

            <p>
              
                Karishna Academy Raigad Colony- E, Thite Vasti, Kharadi, Pune,
                Maharashtra 411014
             
            </p>


            <p>
            Registered Address: Anand Nagar, Sanaswadi, Pune, Shirur, Maharashtra, 412208
            </p>
          </div>



          <div className="email-card">
          <div className="email-logo">
          <FontAwesomeIcon icon={faEnvelope}/>
          </div>
            <h3> Email Id</h3>
            <p>karishnaacademy@gmail.com</p>
            <p>datascience.classes22@gmail.com</p>
          </div>
        
      


















<div className="map-container">
<iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3782.5956917703056!2d73.9335354!3d18.547164!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c3d647939cd7%3A0xb6a33e8b357d6658!2sKarishna%20Academy!5e0!3m2!1sen!2sin!4v1714036978420!5m2!1sen!2sin"
  width="600"
  height="450"

  style={{ border: 0 }}
  allowfullscreen=""
  loading="lazy"
  referrerpolicy="no-referrer-when-downgrade"


     
     
></iframe>
</div>
</>

  );
};

export default Contact;
