import React from 'react';
import './FullStackDevelopment.css';
import Java from '../Images/java.jpg';
import Javascript from '../Images/Javascript.png';
import html5 from '../Images/html5.jpg';
import Css3 from '../Images/Css3.jpg';
import reactjs from '../Images/reactjs.jpg';
import angularjs from '../Images/angularjs.jpg';
import Webservices from '../Images/Webservices.jpg';
import Postman from '../Images/Postman.png';
import Mysql from '../Images/Mysql.jpg';
import Lamb  from '../Images/Lamb.png';



import jsPDF from '../Certificate/jsPDF4.pdf';
import './AWS.css';

import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { AiOutlineDownload } from "react-icons/ai";
import {pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;










const FullStackDevelopment = () => {




  const awsData = {
    title: 'JAVA ',
    description: 'Explore our Java services .',
    image: Java,
  };
  return (
    
    <div className="fullstack-development-container">
     
      <div className="card-container">
        {/* First set of cards */}
        
          
        <div className="aws-devops-page">
    
      <div className="card-container">
        <div className="cardfullstack">
          <img src={awsData.image} alt="AWS"  />
          <div className="card-content">
            <h2>{awsData.title}</h2>
            <p>{awsData.description}</p>
            <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={jsPDF}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download
          </Button>
        </Row>
          </div>
        </div>
        
      </div>
    </div>
       
        {/* Add more cards for Java advance, JDK 1.8, etc. */}
       <div className="cardfullstack">
          <img src={Javascript} alt="HTML5" />
          <div className="card-content">
            <h3>❖Java script</h3>
            <p>Master the programming language of the web.</p>
          </div>
        </div>
        </div>

      <div className="card-container">
        {/* Second set of cards */}
        <div className="cardfullstack">
          <img src={html5} alt="HTML5" />
          <div className="card-content">
            <h3>❖HTML5</h3>
            <p>Learn the latest markup language for web development.</p>
          </div>
        </div>
        {/* Add more cards for CSS3, JavaScript, etc. */}
          <div className="cardfullstack">
          <img src={Css3} alt="HTML5" />
          <div className="card-content">
            <h3>❖Css3</h3>
            <p>the latest version of the standard markup language used for describing the presentation of a document written in a markup language.</p>
          </div>
        </div>
      </div>


      <div className="card-container">
        {/* React JS card */}
        <div className="cardfullstack">
          <img src={reactjs} alt="React JS" />
          <div className="card-content">
            <h3>React JS</h3>
            <p>Build powerful and dynamic user interfaces with React.</p>
          </div>
        </div>
        {/* Angular JS card */}
        <div className="cardfullstack">
          <img src={angularjs} alt="Angular JS" />
          <div className="card-content">
            <h3>Angular JS</h3>
            <p>Create single-page web applications with AngularJS.</p>
          </div>
        </div>
      </div>


      <div className="card-container">
        {/* Web services card */}
        <div className="cardfullstack">
          <img src={Webservices}alt="Web services" />
          <div className="card-content">
            <h3>Web services</h3>
            <p>Learn how to build and consume web services in your applications.</p>
          </div>
        </div>
        {/* Postman API card */}
        <div className="cardfullstack">
          <img src={Postman} alt="Postman API" />
          <div className="card-content">
            <h3>Postman API</h3>
            <p>Explore the features of Postman for testing and developing APIs.</p>
          </div>
        </div>
      </div>




      <div className="card-container">
        {/* MySQL card */}
        <div className="cardfullstack">
          <img src={Mysql} alt="MySQL" />
          <div className="card-content">
            <h3>MySQL</h3>
            <p>Learn about database management using MySQL.</p>
          </div>
        </div>
        {/* Project Lombok card */}
        <div className="cardfullstack">
          <img src={Lamb} alt="Project Lombok" />
          <div className="card-content">
            <h3>Project Lombok</h3>
            <p>Explore the features of Project Lombok for reducing boilerplate code in Java projects.</p>
          </div>
        </div>
      </div>




    </div>
  );
};

export default FullStackDevelopment;
