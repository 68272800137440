// Courses.js

import React from 'react';
import './NEET.css'; // Import CSS file for dynamic styles

const NEE = () => {
  return (
    <>
    <div className="container">
      <h2>CBSE BOARD</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">CBSE X Grade</h5>
            </div>
          </div>
        </div>
        {/* Add similar card components for CBSE IX Grade and CBSE VIII Grade */}
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">CBSE IX Grade</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">CBSE VIII Grade</h5>
            </div>
          </div>
        </div>
      </div>
      {/* Add similar sections for other boards */}
    </div>




    <div className="container">
      <h2>SSC BOARD</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">SSC std 10th</h5>
            </div>
          </div>
        </div>
        {/* Add similar card components for CBSE IX Grade and CBSE VIII Grade */}
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">SSC std 9th</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">SSC std 8th</h5>
            </div>
          </div>
        </div>
      </div>
      {/* Add similar sections for other boards */}
    </div>






    <div className="container">
      <h2>Engineering</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">JEE XI</h5>
            </div>
          </div>
        </div>
        {/* Add similar card components for CBSE IX Grade and CBSE VIII Grade */}
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">JEE XII</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">IIT-JEE (XI &XII)</h5>
            </div>
          </div>
        </div>
         <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">MHT-CET (XI & XII)</h5>
            </div>
          </div>
        </div>
      </div>
      {/* Add similar sections for other boards */}
    </div>



    <div className="container">
      <h2>NEET-Medical</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">NEET XI (Medical)</h5>
            </div>
          </div>
        </div>
        {/* Add similar card components for CBSE IX Grade and CBSE VIII Grade */}
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">NEET XII (Medical)</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="cardN">
            <div className="cardN-body">
              <h5 className="cardN-title">NEET XI & XII (Medical)</h5>
            </div>
          </div>
        </div>
        </div>
        </div>










    </>
  );
}

export default NEE;
