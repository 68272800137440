import React from 'react';
import './TopCategories.css'; // Import CSS file for styling
import logo1 from '../Images/logo1.png';
import logo2 from '../Images/logo2.png';
import logo3 from '../Images/logo3.png';
import logo4 from '../Images/logo4.png';
import logo5 from '../Images/logo5.png';
import logo6 from '../Images/logo6.png';


const TopCategories = () => {
  return (
    <>
    
    <div className="background-image image1"> 
       
    <h1 className='inf' >Top Categories</h1>
 
    <div className="announcements-containe">
    
    
      {/* Card 1 */}
      <div className="announcement-car">
      
        <img src={logo1} alt="Logo 1" className="logo" />
        <h3>Full Stack Development</h3>
      </div>
      {/* Card 2 */}
      <div className="announcement-car">
       
        <img src={logo2} alt="Logo 2" className="logo" />
        <h3>Software Testing</h3>
      </div>
      {/* Card 3 */}
      <div className="announcement-car">
       
        <img src={logo3} alt="Logo 3" className="logo" />
        <h3>Data Science with Python</h3>
      </div>
      {/* Card 4 */}
      <div className="announcement-car">
      
        <img src={logo4} alt="Logo 4" className="logo" />
        <h3>Cloud Computing</h3>
      </div>
      {/* Card 5 */}
      <div className="announcement-car">
       
        <img src={logo5} alt="Logo 5" className="logo" />
        <h3>Cyber Security</h3>
      </div>
      {/* Card 6 */}
      <div className="announcement-car">
      
        <img src={logo6} alt="Logo 6" className="logo" />
        <h3>Modular Courses</h3>
      </div>
    </div>
    </div>
    </>
       
  );
}

export default TopCategories;
