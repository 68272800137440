import React from "react";
import './../SocialFollow/SocialFollow.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faLinkedin,
    faInstagram,
   
    
    
  } from "@fortawesome/free-brands-svg-icons";

export default function SocialFollow() {
  return (
    <div className="social-container">
      <a href="https://www.instagram.com/karishna.academy/"
        className="instagram social">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
        
        
      </a>
      
      <a href="https://www.youtube.com/@mybestguru"
        className="youtube social">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a href="https://www.facebook.com/people/Karishna-Academy/100064206874537/"
        className="facebook social">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a href="https://www.linkedin.com/in/karishna-academy-6723a2307/" className="twitter social">
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </a>
      

 
     

      

     
    
       </div>

  );
}