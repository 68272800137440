// MBABBABCOMPage.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import './MBA.css'; // Import CSS file for styling

const MBA = () => {
  return (
    <div className="mbabbabcom-page">
      <h1>Welcome to MBA Course</h1>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>MBA Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for MBA Course</p>
                <p>Master of Business Administration (MBA) is a postgraduate program that provides advanced knowledge and skills in business management.</p>
                <p>Benefits after completing MBA:</p>
                <ul>
                  <li>Enhanced leadership abilities</li>
                  <li>Global career opportunities</li>
                  <li>Higher earning potential</li>
                </ul>
                <p>Job Opportunities:</p>
                <p>MBA graduates can pursue careers in various sectors, including:</p>
                <ul>
                  <li>Finance</li>
                  <li>Marketing</li>
                  <li>Consulting</li>
                  <li>Operations</li>
                </ul>
                <p>Placement Assistance: We offer dedicated support to help our MBA graduates secure promising job opportunities.</p>
              </div>
            </div>
          </div>
          </div>
        </div >
        </div>
        
  );
};

export default MBA;
