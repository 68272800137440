import React from "react";
import "./PythonDevelopment.css";
import BasicPython from "../Images/BasicPython.jpg";
import AdvancePython from "../Images/AdvancePython.jpg";
import FileHandling from "../Images/FileHandling.jpg";
import Numpy from "../Images/Numpy.png";
import Pandas from "../Images/Pandas.png";
import Flask from "../Images/Flask.png";
import Django from "../Images/Django.jpg";
import AI from "../Images/AI.jpg";








// PythonDevelopment.js

const PythonDevelopment = () => {
  return (
    <div className="python-development-container">
      {/* Basic Python Card */}
      <div className="python-development-card">
        <img
          src={BasicPython}
          alt="Pythone"
          className="python-development-image"
        />
        <div className="python-development-content">
          <h2>Basic Python</h2>
          <p>
          Learn the basics of Python programming language,<br/>
          including syntax,data types, and control structures.
          </p>
        </div>
      </div>
      {/* Advance Python Card */}
      <div className="python-development-card">
        <img
          src={AdvancePython}
          alt="Pythonw"
          className="python-development-image"
        />
        <div className="python-development-content">
          <h2>Advance Python</h2>
          <p>
            Explore advanced Python concepts such as object-oriented
            programming, decorators, and generators.
          </p>
        </div>
      </div>
      {/* File Handling Card */}
      <div className="python-development-card">
        <img
          src={FileHandling}
          alt="Pythonq"
          className="python-development-image"
        />
        <div className="python-development-content">
          <h2>File Handling</h2>
          <p>
            Master file handling techniques in Python for reading, writing, and
            manipulating files.
          </p>
        </div>
      </div>
      {/* Numpy Card */}
      <div className="python-development-card">
        <img
          src={Numpy}
          alt="Numpy"
          className="python-development-image"
        />
        <div className="python-development-content">
          <h2>Numpy</h2>
          <p>
            Learn the fundamental library for scientific computing in Python.
          </p>
        </div>
      </div>
      {/* Pandas Card */}
      <div className="python-development-card">
        <img
          src={Pandas}
          alt="Pandas"
          className="python-development-image"
        />
        <div className="python-development-content">
          <h2>Pandas</h2>
          <p>
            Explore data manipulation and analysis using the Pandas library.
          </p>
        </div>
      </div>
      {/* Flask Card */}
      <div className="python-development-card">
        <img
          src={Flask}
          alt="Flask"
          className="python-development-image"
        />
        <div className="python-development-content">
          <h2>Flask</h2>
          <p>
            Build web applications with the lightweight and flexible Flask
            framework.
          </p>
        </div>
      </div>
      {/* Django Card */}
      <div className="python-development-card">
        <img
          src={Django}
          alt="Django"
          className="python-development-image"
        />
        <div className="python-development-content">
          <h2>Django</h2>
          <p>
            Create web applications quickly and efficiently with the Django web
            framework.
          </p>
        </div>
      </div>
      {/* Artificial Intelligence Card */}
      <div className="python-development-card">
        <img src={AI} alt="AI" className="python-development-image" />
        <div className="python-development-content">
          <h2>Artificial Intelligence</h2>
          <p>Discover the world of AI and machine learning using Python.</p>
        </div>
      </div>
    </div> 
  );
};

export default PythonDevelopment;
