import React from 'react';
import './Gallery.css'; // Import CSS file for styling
import age from './age.png'; // Import images
import image1 from './image1.jpg';
import age1 from './age1.png';
import age4 from './age4.png';

import age3 from './age3.jpg';
import age6 from './age6.png';




const Gallery = () => {
  return (
    <div className="gallery">
      <div className="gallery-item">
        <img src={age} alt="Im" />
      </div>
      <div className="gallery-item">
        <img src={image1} alt="Imm" />
      </div>
      <div className="gallery-item">
        <img src={age1} alt="Ima" />
      </div>








      <div className="gallery-item">
        <img src={age6} alt="Im" />
      </div>
      <div className="gallery-item">
        <img src={age3} alt="Imm" />
      </div>
      <div className="gallery-item">
        <img src={age4} alt="Ima" />
      </div>
    </div>
  );
};

export default Gallery;
