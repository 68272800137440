// AWSDevOpsPage.js

import React from 'react';
import './AWS.css';
import awsImage from '../Images/awsImage.jpg';
import devopsImage from '../Images/devopsImage.jpg';



import jsPDF from '../Certificate/jsPDF.pdf';



import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { AiOutlineDownload } from "react-icons/ai";
import {pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const AWS = () => {
  const awsData = {
    title: 'AWS Services',
    description: 'Explore our AWS services for cloud computing.',
    image: awsImage,
  };

  const devopsData = {
    title: 'DevOps Solutions',
    description: 'Discover our DevOps solutions for efficient software development and deployment.',
    image: devopsImage,
  };


  return (
    <div className="aws-devops-page">
      <h1>AWS and DevOps</h1>
      <div className="card-container">
        <div className="card">
          <img src={awsData.image} alt="AWS" />
          <div className="card-content">
            <h2>{awsData.title}</h2>
            <p>{awsData.description}</p>
            <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={jsPDF}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download
          </Button>
        </Row>
          </div>
        </div>
        <div className="card">
          <img src={devopsData.image} alt="DevOps" />
          <div className="card-content">
            <h2>{devopsData.title}</h2>
            <p>{devopsData.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AWS;
