import React from 'react';

const PaymentAndRefundPolicy = () => {

  return (
  <>

<div>
            <h1>Payment and Refunds Policy</h1>
            <section>
                <h2>1. Course Enrollment and Eligibility</h2>
                <p>By enrolling in a course at Karishna Academy, you agree to abide by our Terms of Service and Refund Policy.</p>
            </section>
            <section>
                <h2>2. Refund Eligibility</h2>
                <h3>Refunds Before the Program Starts:</h3>
                <ul>
                    <li><strong>Within 7 Days of Contract:</strong> If written notice of withdrawal is received within 7 days after the contract is made and before the commencement of the period of instruction specified in the contract, 90% of any tuition and fees paid will be refunded.</li>
                    <li><strong>30 Days or More Before Program Start:</strong> If written notice of withdrawal is received 30 days or more before the commencement of the period of instruction, the institution may retain 20% of total tuition only.</li>
                    <li><strong>Less Than 30 Days Before Program Start:</strong> If written notice of withdrawal is received less than 30 days before the commencement of the period of instruction, the institution may retain 20% of the total tuition only.</li>
                </ul>
                <h3>Refunds After the Program Starts:</h3>
                <ul>
                    <li><strong>Up to 10% of Program Duration:</strong> If withdrawal occurs within the first 10% of the period of instruction, the institution may retain 30% of the tuition due.</li>
                    <li><strong>More Than 10% and Up to 30% of Program Duration:</strong> If withdrawal occurs within this range, the institution may retain 50% of the tuition due.</li>
                    <li><strong>Beyond 30% of Program Duration:</strong> No refund is required.</li>
                    <li><strong>Non-Attendance of First 30% of Program:</strong> The institution may retain up to 50% of the tuition due.</li>
                </ul>
                <h3>Additional Notes:</h3>
                <ul>
                    <li>Refunds owed to students must be paid within 30 days of receiving written notification of withdrawal.</li>
                    <li>The institution is not responsible for refunding more than the total collected tuition fees.</li>
                    <li>Work experience hours must be provided within 30 days of the contract end date.</li>
                </ul>
            </section>
        </div> 
        
  
    </>
  );
};

export default PaymentAndRefundPolicy;