// MBABBABCOMPage.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import './MBA.css'; // Import CSS file for styling

const BBA = () => {
  return (
    <div className="mbabbabcom-page">
      <h1>Welcome to BBA Course</h1>
      <div className="container">
        <div className="row">
          
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>BBA Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for BBA Course</p>
                <p>Bachelor of Business Administration (BBA) is an undergraduate program that provides foundational knowledge in business management.</p>
                <p>Benefits after completing BBA:</p>
                <ul>
                  <li>Entry-level positions in business organizations</li>
                  <li>Preparation for higher studies in business management</li>
                  <li>Development of leadership and communication skills</li>
                </ul>
                <p>Job Opportunities:</p>
                <p>BBA graduates can explore careers in various fields, such as:</p>
                <ul>
                  <li>Human Resources</li>
                  <li>Sales and Marketing</li>
                  <li>Finance and Accounting</li>
                  <li>Operations Management</li>
                </ul>
                <p>Placement Assistance: We provide comprehensive support to help our BBA graduates kick-start their careers.</p>
              </div>
            </div>
          </div>
        
          
    
        </div>
      </div>
    </div>
  );
};

export default BBA;
