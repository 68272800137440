import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../Images/logo.jpg";


const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={toggleMenu}>
          <img src={logo} alt="Logo" className="logo-image" />
            </Link>
            
          
            <h1 className="slogan">
            Education is the power of life,acquire it !
            </h1>
       
         
         
  
          <div
            className={`menu-icon ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div className="menu-line"></div>
            <div className="menu-line"></div>
            <div className="menu-line"></div>
          </div>
          <ul className={`nav-menu ${isMenuOpen ? "active" : ""}`}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={toggleMenu} >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-links" onClick={toggleMenu} style={{marginRight:"5px",
}}>
                About
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  color: "hsl(0, 72%, 26%)",
                  textDecoration: "none",
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  font:"bold",
                  width:"80px",
                  height:"25px",
                  fontWeight: "bold",
                  justifyContent:"center",
                  marginRight:"10px",


                }} 
              >
                Courses
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">

              <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/4"  onClick={toggleMenu}>
                  AWS
                </Link>

                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/6"  onClick={toggleMenu}>
                  Data Science
                </Link>

                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/5"  onClick={toggleMenu}>
                  Data Analyst
                </Link>

                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/1"  onClick={toggleMenu}>
                  Full Stack Development
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/2"  onClick={toggleMenu}>
                  Web Development
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/3"  onClick={toggleMenu}>
                  Python Development
                </Link>
                
                
                
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/7"  onClick={toggleMenu}>
                  Salesforce Development
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/8"  onClick={toggleMenu}>
                  SAP
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/9"  onClick={toggleMenu}>
                  10/12/NEET/JEE
                </Link>
              </div>
            </li>

           {/*<li className="nav-item">
              <Link to="/placements" className="nav-links" onClick={toggleMenu} style={{                  marginRight:"10px",
}}>
              Activity
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/gallery" className="nav-links" onClick={toggleMenu} style={{                  marginRight:"12px",
}}>
                Gallery
              </Link>
            </li>*/}
            {/*<li className="nav-item">
              <Link
                to="/certificate"
                className="nav-links"
                onClick={toggleMenu}
                style={{                  marginRight:"10px",
              }}

              >
                Placement
              </Link> 
            </li> */}
           {/* <li className="nav-item">
              <Link to="/blogs" className="nav-links" onClick={toggleMenu}   style={{                  marginRight:"10px",
}}>
                Blogs
              </Link> 
            </li>*/}
            <li className="nav-item">
              <Link to="/contact" className="nav-links" onClick={toggleMenu} style={{                  marginRight:"12px",
}}>
                Contact
              </Link>
            </li>
           




            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  color: "hsl(0, 72%, 26%)",
                  textDecoration: "none",
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  font:"bold",
                  width:"80px",
                  height:"25px",
                  fontWeight: "bold",
                  justifyContent:"center",
                  marginRight:"22px",


                }} 
              >
                College Courses
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">

              <div className="dropdown-divider"></div>
               <Link className="dropdown-item" to="/11"  onClick={toggleMenu}>
                  MBA
                </Link>

                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/10"  onClick={toggleMenu}>
                  MCA
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/13"  onClick={toggleMenu}>
                  BCA
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/14"  onClick={toggleMenu}>
                  BBA
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/15"  onClick={toggleMenu}>
                  BCOM
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/12"  onClick={toggleMenu}>
                  Other Courses
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/paymentgateways"  onClick={toggleMenu}>
                  Payment
                </Link>
                
                </div>
            </li>










          



















            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/21"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  color: "hsl(0, 72%, 26%)",
                  textDecoration: "none",
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  font:"bold",
                  width:"80px",
                  height:"25px",
                  fontWeight: "bold",
                  justifyContent:"center",
                  marginRight:"22px",


                }} 
              >
                Terms And Conditions
                   </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              
               
              <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/21"  onClick={toggleMenu}>
                Terms And Conditions
                </Link>



                <div className="dropdown-divider"></div>
               <Link className="dropdown-item" to="/privacy-policy"  onClick={toggleMenu}>
               Privacy Policy
                </Link>






                

 
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/22"  onClick={toggleMenu}>
                Payment And Refund Policy
                </Link>
                
                
                </div>
            </li>
          </ul>



      
      </div>



     
    
      </nav>
    </>
  );
};

export default Navbar;
