// AcademicCoursesPage.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import './Othere.css'; // Import CSS file for styling

const Othere= () => {
  return (
    <div className="academic-courses-page">
      <h1>Welcome to Our Academic Courses</h1>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>M.SC Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for M.SC Course</p>
                <p>Detailed content about M.SC Course.</p>
                <p>Placement Assistance: We provide support for M.SC graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>B.SC Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for B.SC Course</p>
                <p>Detailed content about B.SC Course.</p>
                <p>Placement Assistance: We provide support for B.SC graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>MSW Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for MSW Course</p>
                <p>Detailed content about MSW Course.</p>
                <p>Placement Assistance: We provide support for MSW graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>BSW Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for BSW Course</p>
                <p>Detailed content about BSW Course.</p>
                <p>Placement Assistance: We provide support for BSW graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>M.A Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for M.A Course</p>
                <p>Detailed content about M.A Course.</p>
                <p>Placement Assistance: We provide support for M.A graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>B.A Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for B.A Course</p>
                <p>Detailed content about B.A Course.</p>
                <p>Placement Assistance: We provide support for B.A graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>MCOM Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for MCOM Course</p>
                <p>Detailed content about MCOM Course.</p>
                <p>Placement Assistance: We provide support for MCOM graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>PHD Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for PHD Course</p>
                <p>Detailed content about PHD Course.</p>
                <p>Placement Assistance: We provide support for PHD graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>Diploma Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for Diploma Course</p>
                <p>Detailed content about Diploma Course.</p>
                <p>Placement Assistance: We provide support for Diploma graduates to secure job placements.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>PG Diploma Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for PG Diploma Course</p>
                <p>Detailed content about PG Diploma Course.</p>
                <p>Placement Assistance: We provide support for PG Diploma graduates to secure job placements.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Othere;
