import React from 'react';
import './Announcements.css'; // Import your CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';




const Placements = () => {
  return (
    <>
    <h1 className='info'>📢 Karishna Academy Announcements 📢</h1>
    <div className="announcements-container">
      
      <div className="announcement-card">
      <FontAwesomeIcon icon={faCalendarAlt} style={{ color: '#FF5733', fontSize: '50px' }} />
        <h2>Upcoming Holidays</h2>
        <p>Observance Days: The academy will be closed on designated national holidays. Classes will resume following the standard schedule.</p>
      </div>

      <div className="announcement-card">
      <FontAwesomeIcon icon={faFlask} style={{ color: '#ff0000', fontSize: '50px' }} /> 
        <h2>Special Events</h2>
        <p>Annual Science Fair: Get ready for a day of discovery and innovation at our Annual Science Fair, showcasing projects from our talented students.</p>
      </div>

      <div className="announcement-card">
      <FontAwesomeIcon icon={faGraduationCap} style={{ color: '#3366ff', fontSize: '50px' }} />

        <h2>Functions and Celebrations</h2>
        <p>Graduation Ceremony: We invite you to celebrate the accomplishments of our graduating students at our upcoming ceremony. It’s a day of joy and pride for the Karishna Academy family.</p>
      </div>

      <div className="announcement-card">
      <FontAwesomeIcon icon={faBriefcase} style={{ color: '#ff9933', fontSize: '50px' }} />
        <h2>Professional Development</h2>
        <p>Company Interviews: Interviews with leading IT companies are scheduled periodically. Students looking to participate should prepare their resumes in advance.</p>
      </div>

      <div className="announcement-card">
      <FontAwesomeIcon icon={faChalkboardTeacher} style={{ color: '#3399ff', fontSize: '50px' }} />
        <h2>Workshops and Seminars</h2>
        <p>Latest Tech Trends: Don’t miss our seminar on the latest trends in technology, featuring insights from industry experts. Stay updated with these events and participate actively to make the most of your time at Karishna Academy!</p>
      </div>
    </div>
    </>
  );
}

export default Placements;
