import React from "react";
import "./InquiryForm.css";
import { useRef,useState} from "react";
import emailjs from "@emailjs/browser";

const InquiryForm = ({ onClose }) => {
  const form = useRef();

  const [successMessage, setSuccessMessage] = useState(false);



  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_30oglmh", "template_en4jhrc", form.current, {
        publicKey: "eZc4L7b9l2MBV5cuA",
      })
      .then(
        (result) => {
          setSuccessMessage(true);
          
          setTimeout(() => setSuccessMessage(false), 3000);
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }

      );
  };

  return (
    <div className="inquiry-form-container">
      <div className="inquiry-form">
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
        <h2>Contact Us</h2>
        {successMessage && (
          <div className="success-message"> 
          "Form submitted successfully Close Tab!"</div>
        )}
        <form ref={form} onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName">Full Name:</label>
            <input type="text" id="fullName" name="name"
            required /> 
          </div>
          <div className="form-group">
            <label htmlFor="mobileNumber">Mobile Number:</label>
            <input type="tel" id="mobileNumber" name="mo" 
             required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email"   
            required />
          </div>
          <div className="form-group">
            <label htmlFor="course">Course:</label>
            <input type="text" id="course" name="courses"  
             required /> 
            </div>

          <button type="submit" >Submit</button>
        </form>
      </div>
    </div>
  );
};

export default InquiryForm;
