import React from 'react';
import './Blogs.css'; // Import your CSS file for styling

const Blogs = () => {
  return (
    <div className="blog-container">
      <div className="blog-card" onClick={() => console.log("Class Schedule clicked")}>
        <h2 className='info'>Class Schedule</h2>
        <p>
          At Karishna Academy, we understand the importance of flexibility in education. That’s why our doors open bright and early at 7 AM and stay open until 9 PM, every single day of the week, including Sundays. This ensures that no matter your schedule, there’s a time for you to learn and grow with us. Additionally, we embrace the digital age with our comprehensive online training programs, making quality education accessible to you wherever you are.
        </p>
      </div>

      <div className="blog-card" onClick={() => console.log("Syllabus clicked")}>
        <h2 className='info'>Syllabus</h2>
        <p>
          Karishna Academy prides itself on offering a curriculum that is at the forefront of technological and scientific education. Our courses for IT professionals and 11th and 12th-grade science students are designed to be comprehensive and forward-thinking, aligning with the latest advancements and market trends. From the basics to the most sophisticated tools and technologies, our syllabus is constantly updated to ensure our students are market-ready and ahead of the curve.
        </p>
      </div>

      <div className="blog-card" onClick={() => console.log("Resources clicked")}>
        <h2 className='info'>Resources</h2>
        <p>
          Karishna Academy is dedicated to providing a wealth of resources to support our students’ educational journey:
          <ul>
            <li>E-Materials: A comprehensive collection of digital study materials is readily available to enhance the learning experience.</li>
            <li>Exclusive Study App: Our custom-designed institute app offers an interactive platform for students to engage with their coursework anytime, anywhere.</li>
            <li>Library Facilities: Our on-site library boasts a vast array of books and reference materials, serving as a quiet haven for study and research.</li>
          </ul>
        </p>
      </div>

      <div className="blog-card" onClick={() => console.log("Assignments clicked")}>
        <h2 className='info'>Assignments</h2>
        <p>
          We are committed to your growth and success. Here’s how we support our students:
          <ol>
            <li>
              Daily Assignments:
              <ul>
                <li>We provide daily assignments based on completed topics. These assignments reinforce your learning and help you apply theoretical knowledge to practical scenarios.</li>
              </ul>
            </li>
            <li>
              Comprehensive Notes:
              <ul>
                <li>Our meticulously prepared study notes serve as valuable resources. They condense complex concepts, making them easier to understand and revise.</li>
              </ul>
            </li>
            <li>
              Weekly Tests:
              <ul>
                <li>Regular weekly tests assess your understanding of the material. These assessments help track your progress and identify areas for improvement.</li>
              </ul>
            </li>
            <li>
              Mock Interviews:
              <ul>
                <li>To prepare you for real-world scenarios, we conduct mock interviews. These simulate job interviews, allowing you to practice your communication, problem-solving, and technical skills.</li>
              </ul>
            </li>
          </ol>
        </p>
      </div>
    </div>
  );
}

export default Blogs;
