// MBABBABCOMPage.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import './MBA.css'; // Import CSS file for styling

const BCOM = () => {
  return (
    <div className="mbabbabcom-page">
      <h1>Welcome to BCOM Course</h1>
      <div className="container">
        <div className="row">
          
         
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <FontAwesomeIcon icon={faUniversity} />
                <h3>BCOM Course</h3>
              </div>
              <div className="card-body">
                <p>UGC Approved A+ Top University</p>
                <p>Admission Open for BCOM Course</p>
                <p>Bachelor of Commerce (BCOM) is an undergraduate program that provides a strong foundation in commerce, accounting, finance, and related subjects.</p>
                <p>Benefits after completing BCOM:</p>
                <ul>
                  <li>Wide range of career opportunities</li>
                  <li>Opportunities for further specialization</li>
                  <li>Development of analytical and numerical skills</li>
                </ul>
                <p>Job Opportunities:</p>
                <p>BCOM graduates can pursue careers in:</p>
                <ul>
                  <li>Accounting and Auditing</li>
                  <li>Banking and Finance</li>
                  <li>Taxation</li>
                  <li>Business Analysis</li>
                </ul>
                <p>Placement Assistance: We assist our BCOM graduates in finding suitable job placements in various industries.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BCOM;