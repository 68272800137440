// SalesforceDevelopmentCard.js


import salesforceImage from '../Images/salesforceImage.jpg';

import React from 'react';








import jsPDF from '../Certificate/jsPDF5.pdf';
import './AWS.css';

import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { AiOutlineDownload } from "react-icons/ai";
import {pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const SalesforceDevelopment = () => {
  const awsData = {
    title: ' Sales Force Development ',
    description: 'Explore our SalesforceDevelopment .',
    image: salesforceImage,
  };

  
  

  return (
    <div className="aws-devops-page">
      <h1>Sales Force Development</h1>
      <div className="card-container">
        <div className="card">
          <img src={awsData.image} alt="AWS"  />
          <div className="card-content">
            <h2>{awsData.title}</h2>
            <p>{awsData.description}</p>
            <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={jsPDF}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download
          </Button>
        </Row>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default SalesforceDevelopment;
