// DataAnalystCard.js

import React from 'react';

import dataAnalystImage from '../Images/dataAnalystImage.jpg'; // Import your image file







import jsPDF from '../Certificate/jsPDF2.pdf';
import './AWS.css';

import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { AiOutlineDownload } from "react-icons/ai";
import {pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const DataAnalyst = () => {
  const awsData = {
    title: 'Data Analyst ',
    description: 'Explore our Data Analyst services .',
    image: dataAnalystImage,
  };

  
  

  return (
    <div className="aws-devops-page">
      <h1>Data Analyst</h1>
      <div className="card-container">
        <div className="card">
          <img src={awsData.image} alt="AWS"  />
          <div className="card-content">
            <h2>{awsData.title}</h2>
            <p>{awsData.description}</p>
            <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={jsPDF}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download
          </Button>
        </Row>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default DataAnalyst;
